/*
  TextInput Component Style
*/
.form-control textarea {
  font-family: 'Open Sans';
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1px solid #777;
  padding: 5px;
  text-align: left;
  outline: none;
  font-size: 14px;
  width: 100%;
  -webkit-transition: background 0.25s ease-in;
  -moz-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
  resize: none;
}

.form-control textarea:focus {
  border-bottom: 1px solid #287737;
  background: rgba(40, 119, 55, 0.2);
}

.form-control.error textarea {
  background: transparent;
  color: #fff;
  border: 0;
  border-bottom: 1px solid #c6103a;
  padding: 5px;
  text-align: left;
  outline: none;
  font-size: 14px;
  width: 100%;
  -webkit-transition: background 0.25s ease-in;
  -moz-transition: background 0.25s ease-in;
  -o-transition: background 0.25s ease-in;
  transition: background 0.25s ease-in;
}

.form-control.error textarea:focus {
  border-bottom: 1px solid #c6103a;
  background: rgba(198, 16, 58, 0.2);
}
