/* Checkbox */

.checkbox {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.checkbox img {
  width: 20px;
  margin-right: 10px;
}

.checkbox-label {
  color: #adadad;
  font-size: 14px;
}
