/* The container must be positioned relative: */
.custom-select {
  position: relative;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: '';
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: #787878 transparent transparent transparent;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #787878;
  padding: 8px 20px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  text-align: left;
  font-size: 13px;
}

.select-selected {
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.5);
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: #1d242b;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: #22282f;
}

/* new select */

.select-container {
  position: relative;
}

.select-container .select-list {
  position: relative;
  color: #a9aaab;
  cursor: pointer;
  border-bottom: 1px solid #a9aaab;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-container .select-list .selected {
  padding-left: 5px;
  user-select: none;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-container .select-list img {
  position: relative;
}

.select-container .select-list-options {
  position: absolute;
  width: 100%;
  z-index: 1500;
  max-height: 400px;
  box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

.select-container .select-list-options .option {
  color: #a9aaab;
  display: flex;
  height: 32px;
  padding: 5px;
  background: #1e272f;
  border-bottom: 1px solid #131b21;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  cursor: pointer;
}

.select-container .select-list-options .option.selected {
  background: #141b21;
}

.select-container .select-list-options .option.active {
  background: #24313f !important;
}

.select-container .select-list-options .option:hover {
  background: #24313f !important;
}

.select-container .select-list-options .option:focus {
  background: #24313f !important;
}

.select-container .select-list-options .option .option-label {
  font-size: 16px;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
