.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  width: 100%;
}

.form-group .link {
  position: relative;
  display: inline-block;
  padding: 7px 0;
  border-radius: 2px;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: Raleway;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  user-select: none;
  margin: 0 5px 0 10px;
  background-color: transparent;
  color: #287737;
}
.form-group .link:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.form-label {
  flex: 0.3;
  font-size: 14px;
  color: #a9aaab;
  text-align: left;
}

.form-value {
  flex: 0.7;
  font-size: 14px;
  color: #a9aaab;
  text-align: left;
  text-overflow: ellipsis;
}

.form-control {
  flex: 0.7;
  position: relative;
  padding: 0 15px;
  text-align: left;
}

.form-control .error-message {
  color: #c6103a;
  font-size: 13px;
}

/*
  Modal Form
*/
.modal-form {
  min-width: 560px;
  text-align: left;
}

.modal-form-header {
  color: #adadad;
  margin-bottom: 60px;
}

.modal-form-content {
  margin-bottom: 30px;
}

.form-section {
  margin-bottom: 60px;
}

.form-section h4 {
  color: #adadad;
  text-transform: uppercase;
  margin-bottom: 30px;
}
