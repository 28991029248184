.cr *,
.cr *::before,
.cr *::after {
  box-sizing: content-box !important;
}

.cr input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cr span {
  line-height: 1.1;
  font-size: 0.95rem;
  font-family: inherit;
  position: relative;
  /* top: -6px; */
}

.cr {
  display: flex;
  position: relative;
  padding-left: 1.8rem;
  cursor: pointer;
  margin-bottom: 0.9rem;
  /* position: relative;
  top: -1.2rem; */
}

.cr input[type='radio'] ~ .cr-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: rgb(36, 49, 63);
  transition: background 250ms;
  border: 1px solid rgba(184, 194, 204, 1);
  border-radius: 2rem;
}

.cr input[type='radio'] ~ .cr-input::after {
  content: '';
  position: absolute;
  display: none;
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 2rem;
  background: rgba(115, 194, 85, 1);
  transition: background 250ms;
}

.cr input:disabled ~ .cr-input::after {
  border-color: rgba(135, 149, 161, 1);
}

.cr input:checked ~ .cr-input::after {
  display: block;
}

.cr input:checked ~ .cr-input {
  background: rgba(30, 42, 55, 1);
  border-color: rgba(115, 194, 85, 1);
}

.cr input[type='radio']:disabled ~ .cr-input {
  background: rgba(30, 42, 55, 0.27);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.5;
  cursor: not-allowed;
}

.cr input[type='radio']:disabled ~ .cr-input::after {
  background: rgba(135, 149, 161, 1);
}

.cr .cr-input::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: rgba(115, 194, 85, 1);
  border-radius: 2rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.cr input + .cr-input::before {
  animation: b-ripple 250ms ease-out;
}

.cr input:checked + .cr-input::before {
  animation-name: b-ripple-duplicate;
}

.cr .cr-input::before {
  visibility: hidden;
}

.cr:first-child .cr-input::before {
  visibility: hidden;
}
