.leaderboard-page {
  display: flex;
  flex-direction: column;
}

.leaderboard-header {
  display: flex;
  justify-content: row;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  position: relative;
  height: 80px;
}

.leaderboard-header .logo {
  position: absolute;
  left: 30px;
  width: auto;
  height: 80px;
  aspect-ratio: 16/9;
  margin-right: 10px;
}

.leaderboard-header h1 {
  font-weight: 700;
  margin: 0;
}

.leaderboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30px;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  flex:1;
  width: 90%;
}

.participant-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  border-style: solid;
  border-radius: 6px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
  margin-top: 4px;
}

.participant-row.first-place {
  background: linear-gradient(90deg, rgba(254, 225, 1, 0.2) 0%, rgba(214, 175, 54, 0.4) 50%, rgba(254, 225, 1, 0.2) 100%);
  border-color: #FFD700;
  transform: scale(1.06);
}

.participant-row.second-place {
  background: linear-gradient(90deg, rgba(215, 215, 215, 0.3) 0%, rgba(167, 167, 173, 0.5) 50%, rgba(215, 215, 215, 0.3) 100%);
  border-color: #C0C0C0;
  transform: scale(1.04);
}

.participant-row.third-place {
  background: linear-gradient(90deg, rgba(167, 112, 68, 0.15) 0%, rgba(130, 74, 2, 0.15) 50%, rgba(167, 112, 68, 0.15) 100%);
  border-color: rgba(205, 127, 50, .4);
  transform: scale(1.02);
}

.slide-in {
  animation-name: slideIn;
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.slide-out {
  animation-name: slideOut;
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: none;
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.participant-row .participant-photo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin-right: 10px;
  overflow: hidden;
  width: 36px;
  height: 36px;
}

.participant-row .participant {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 16px;
}

.participant-row .participant .place {
  margin-right: 10px;
  width: 40px;
}

.participant-row .participant .name {
  margin: 0;
}

.leaderboard-page progress {
  opacity: 0;
}

.leaderboard-page .progress-element {
  display: flex;
  flex-direction: column;
  width: 400px;
  font-size: 16px;
}

.leaderboard-page .progress-container {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  height: 16px;
  margin-top: 4px;
  border-radius: 6px;
  overflow: hidden;
}

.leaderboard-page .progress-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.leaderboard-page .progress-label {
  position: relative;
  font-size: 14px;
}

.leaderboard-page .progress-label::after {
  content: attr(data-progress) '%';
  position: absolute;
  top: 0;
  right: 0;
}

.leaderboard-page .progress {
  background-color: rgb(81, 196, 136);
  height: 18px;
  position: absolute;
  width: 0%;
  transition: width 1.5s ease-in-out;
}
