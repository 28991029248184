.studio-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  padding: 0 60px;
}

.studio-container .studio-layout {
  display: flex;
  /* flex: 1; */
  margin-bottom: 3px;
}

.studio-placeholder {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1e2a36;
  padding: 30px 0 15px 0;
}

.studio-placeholder img {
  width: 40%;
}

.studio-placeholder p {
  width: 35%;
  color: #59595a;
}

.studio-container .studio-configuration {
  min-height: 300px;
  background-color: #141b21;
  display: flex;
  flex: 1;
}

.studio-container .studio-configuration .studio-list {
  height: 100%;
  width: 300px;
  border-right: 2px solid #1e272f;
  position: relative;
}

.studio-container .studio-configuration .studio-list .studio-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  color: #c2c2c2;
  height: 60px;
  border-bottom: 2px solid #1c242d;
  cursor: pointer;
}

.studio-container .studio-configuration .studio-list .studio-list-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.studio-container .studio-configuration .studio-list .studio-list-item:hover {
  background: #24313f;
}

.studio-container .studio-configuration .studio-list .studio-option {
  padding: 0 8px;
}

.studio-container .studio-configuration .studio-list .studio-option img {
  width: 24px;
  height: 24px;
}

.studio-container .studio-configuration .studio-list .studio-option:last-child {
  padding: 0;
}

.studio-container .studio-configuration .studio-list .studio-list-item.active {
  background: #24313f;
}

.studio-container .studio-configuration .studio-list .add-studio {
  color: #2b763a;
  cursor: pointer;
  align-items: center;
  border-top: 2px solid #1e272f;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.studio-container .studio-configuration .studio-list .add-studio img {
  margin-right: 10px;
}

.studio-container .studio-configuration .studio-form {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.instances-list-container {
  height: 100%;
  width: 300px;
  border-left: 2px solid #1e272f;
  position: relative;
}

.instances-list-container .instances-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  color: #c2c2c2;
  height: 60px;
  border-bottom: 2px solid #1c242d;
  cursor: pointer;
}

.instances-list-container .instances-list-item:hover {
  background: #24313f;
}

.instances-list-container .option {
  padding: 0 8px;
}

.instances-list-container .option img {
  width: 24px;
  height: 24px;
}

.instances-list-container .option:last-child {
  padding: 0;
}

.instances-list-container .instances-list-item.active {
  background: #24313f;
}

.instances-list-container .add-button {
  color: #2b763a;
  cursor: pointer;
  align-items: center;
  border-top: 2px solid #1e272f;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.instances-list-container .add-button img {
  margin-right: 10px;
}
