/*
  FileInput Component Style
*/
.form-control .file-input {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form-control .file-input input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.form-control .file-input i {
  color: #287737;
  margin-right: 15px;
  cursor: pointer;
}

.form-control .file-input .file-name {
  color: #287737;
  cursor: pointer;
  margin-left: 15px;
}