.wall-container {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 60px auto 1fr;
  margin: 0 60px;
  height: calc(100% - 20px);
}

.wall-container .wall-no-participants {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  background-color: #1e2a38;
  padding-top: 10%;
  padding-bottom: 10%;
}

.wall-container .wall-no-participants img {
  width: 30%;
  height: auto;
}

.wall-container .wall-no-participants p {
  color: #a9aaab;
  font-size: 1em;
  margin-top: 40px;
}

.wall-container .layout-list {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wall-container .layout-dropdown {
  width: 20%;
}

.wall-container .wall-layout {
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.wall-container .wall-layout-options {
  background: #141b21;
  display: flex;
  flex: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.attendee {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 15px;
  border-bottom: 2px solid #1c242d;
  cursor: pointer;
}

.attendee:hover {
  background: #24313f;
}

.attendee.active {
  background: #24313f;
}

.attendee .general-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.attendee .general-information .name {
  color: #bbbcbc;
  margin-bottom: 5px;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: flex;
  flex-direction: row;
}

.attendee .general-information .location {
  color: #505456;
  font-size: 14px;
}

.attndee .status {
  margin: 5px;
}

.attendee .status .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.videostream-container {
  position: relative;
  display: flex;
  color: #66686a;
}

.video-stream {
  display: flex;
  flex: 2;
  justify-content: space-around;
  align-items: flex-start;
}

.client-os-info {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 5px;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  width: 260px;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
}

.video-stream-opts {
  width: 310px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.video-stream-opts .inline-select {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px auto;
  align-items: center;
  justify-content: space-between;
}

.video-stream-opts .inline-select .inline-select-label {
  margin-right: 10px;
  flex: 0.6;
  text-align: left;
}

.areas-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wall-areas-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.stream-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.stream-container video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.wall-participant-container {
  position: absolute;
  z-index: 101;
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
}

.wall-participant-container .placeholder {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: left top;
  position: relative;
  pointer-events: none;
}

.wall-participant-container .index {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  z-index: 104;
  color: #20b1ee;
}

.wall-participant-container .drop-zone {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #135368;
  z-index: 106;
  display: none;
}

.wall-participant-container .metadata {
  position: absolute;
  z-index: 111;
  left: 0;
  right: 0;
  bottom: 0;
  height: 24px;
  background: rgba(0, 0, 0, 0.3);
  color: #bbbcbc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wall-participant-container .metadata .name {
  font-size: 13px;
}

.wall-participant-container .metadata .status {
  margin: 5px;
}

.wall-participant-container .metadata .status .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.wall-participant-container .video-container {
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  z-index: 105;
  display: none;
}

.wall-participant-container .video-container video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.preview-layout-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}

.preview-layout-options .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.preview-layout-options .title {
  color: #1b252e;
  font-size: 64px;
  font-weight: 900;
  margin-bottom: 30px;
}

.highlight {
  box-shadow: 0 0 0 0 rgba(183, 19, 48, 1);
  transform: scale(1);
  animation: highlight 2s infinite;
}

@keyframes highlight {
  0% {
    box-shadow: 0 0 0 0 rgba(183, 19, 48, 0.9);
  }

  60% {
    box-shadow: 0 0 0 15px rgba(40, 119, 55, 0.1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.background-asset-preview {
  position: absolute;
  top: calc(50% - 35px);
  right: 10%;
  width: 160px;
}

.tools-container {
  width: 245px;
  min-width: 245px;
  padding: 15px;
  border-left: 2px solid rgb(30, 39, 47);
}

.tools-container .custom-button-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.tools-container .custom-button-label .timer-label {
  background: rgb(147, 29, 41);
  color: white;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  font-size: 12px;
}

.status-label {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 45px;
  border-radius: 5px;
  text-align: center;
  right: 0;
  color: white;
  padding: 2px 7px;
  font-weight: 600;
  font-size: 12.5px;
  z-index: 100;
  user-select: none;
  cursor: default;
}
.status-label.on-air {
  background: linear-gradient(90deg, #b4113c 0%, #ce0f25 100%);
}

.property-list {
  margin: 10px;
  align-self: flex-start;
}

.property-list .property {
  display: flex;
  color: white;
  font-size: 11px;
  margin: 4px;
  justify-content: space-between;
}

.property-list .property .label {
  text-align: left;
}
