.layout-editor {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 60px auto 1fr;
  margin: 0 60px;
  height: calc(100% - 20px);
}

.layout-selected {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.layout-selected .name {
  color: rgb(169, 170, 171);
  text-align: right;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.layout-selected .name span {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  color: #a9aaab;
}

.layout-selected .name img {
  width: 24px;
  margin-right: 10px;
}

.layout-participants {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.participant-count {
  color: #a9aaab;
  text-align: right;
}

.layout-content {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  align-self: center;
  margin-bottom: 3px;
  position: relative;
}

.layout-content div {
  box-sizing: border-box;
}

.layout-areas-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
}

.layout-list-container {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: #141b21;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 300px;
}

.layout-cropper {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: #141b21;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

.layout-list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 10px;
  border-bottom: 2px solid #1c242d;
  cursor: pointer;
  user-select: none;
}

.layout-list-item .layout-options {
  display: flex;
  flex-direction: row;
}

.layout-list-item .option {
  margin: 0 4px;
}

.layout-list-item .option img {
  width: 24px;
  height: 24px;
}

.drag-handle {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 8px;
  cursor: move;
}

.drag-handle img {
  width: 24px;
  height: 24px;
}

.layout-list-item:not(.active):hover {
  color: #2b763a;
  padding-left: 35px;
}

.layout-list-item.active {
  background: #24313f;
}

.layout-list-container .add-layout {
  color: #2b763a;
  cursor: pointer;
  align-items: center;
  border-top: 2px solid #1e272f;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.layout-list-container .add-layout img {
  margin-right: 10px;
}

.layout-list-container .layout-list-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #c2c2c2;
  font-size: 16px;
  border-bottom: 2px solid #1c242d;
}

.layout-list-container .layout-list-tabs .tab {
  flex: 1;
  padding: 15px 10px;
  cursor: pointer;
  user-select: none;
}

.layout-list-container .layout-list-tabs .tab.active {
  background-color: #36424d;
}

.layout-configuration-container {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: #141b21;
  border-left: 2px solid #1e272f;
  min-height: 300px;
}

.layout-assets {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: #141b21;
  position: relative;
  min-height: 300px;
}

.layout-assets .asset-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid #1e272f;
  color: #65696f;
}

.layout-assets .asset-list {
  top: 55px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.layout-assets .asset-list::-webkit-scrollbar,
.scrollbar::-webkit-scrollbar {
  width: 6px;
}
.layout-assets .asset-list::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.layout-assets .asset-list::-webkit-scrollbar-thumb,
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.layout-editor-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-color: #141b21;
}

.layout-configuration {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
}

.layout-configuration-actions {
  justify-content: center;
  display: flex;
  align-items: center;
}

.layout-configuration-actions button {
  margin-right: 20px;
}

.blocks-container {
  text-align: left;
  padding: 10px;
  color: white;
}

.block {
  margin: 0 10px;
  background-color: rgb(23, 31, 39);
  display: flex;
  width: 100px;
  height: 100px;
  float: left;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #adadad;
  box-shadow: 0px 6px 16px 1px rgba(0, 0, 0, 0.5);
  cursor: grab;
  user-select: none;
}

.block img {
  pointer-events: none;
  cursor: grab;
}

.drop-hover {
  position: absolute;
  opacity: 0.5;
  display: none;
  visibility: hidden;
  z-index: 110;
}

.dropZone {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #135368;
  z-index: 103;
  display: none;
}

.area-metadata {
  position: absolute;
  z-index: 111;
  left: 0;
  right: 0;
  bottom: 0;
  height: 34px;
  background: rgba(0, 0, 0, 0.3);
  color: #bbbcbc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-line {
  position: absolute;
  pointer-events: none;
  z-index: 220;
}

.tooltip {
  visibility: visible;
  width: 120px;
  background-color: #1e272f;
  color: #a9aaab;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 25%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 1;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

/* Tooltip arrow */
.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e272f transparent transparent transparent;
}
