.icon-button {
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 6px;
  cursor: pointer;
  margin: 0;
  justify-content: center;
  align-items: center;
  outline: none;
}

.icon-button .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1e272f;
  color: #a9aaab;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

/* Tooltip arrow */
.icon-button .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e272f transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.icon-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
