.dropdown {
  display: block;
  position: relative;
}

.dropdown .dropdown-button {
  height: 40px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.dropdown .dropdown-button span {
  color: #a9aaab;
  pointer-events: none;
  font-size: 16px;
}

.dropdown .dropdown-button img {
  margin-right: 5px;
  pointer-events: none;
}

.dropdown .dropdown-button .arrow {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  pointer-events: none;
}

.dropdown .dropdown-button:hover {
  background-color: #1f2833;
}

.dropdown .dropdown-button.open {
  background-color: #1f2833;
}

.dropdown .dropdown-menu {
  position: absolute;
  z-index: 9999;
  visibility: hidden;
  background-color: #1f2833;
  top: 41px;
  left: 0;
  right: 0;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.5);
}

.dropdown .dropdown-menu .dropdown-menu-item {
  min-height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #131b21;
}

.dropdown .dropdown-menu .dropdown-menu-item:hover {
  background-color: #24313f;
}

.dropdown .dropdown-menu .dropdown-menu-item:last-child {
  border-bottom: 0;
}
