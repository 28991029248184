.modal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-header-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.modal-wrapper {
  background-color: #1e2a36;
  padding: 20px;
  height: calc(100% - 40px);
}

.modal-content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  height: calc(100% - 40px);
  overflow-y: auto;
}

.modal-close-btn {
  color: rgb(75, 95, 116);
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
}

.modal-wrapper.fade-in {
  animation: fadeInModal ease-in-out 0.4s;
  -webkit-animation: fadeInModal ease-in-out 0.4s;
  -moz-animation: fadeInModal ease-in-out 0.4s;
  -o-animation: fadeInModal ease-in-out 0.4s;
  -ms-animation: fadeInModal ease-in-out 0.4s;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-wrapper.fade-out {
  animation: fadeOutModal ease-in-out 0.4s;
  -webkit-animation: fadeOutModal ease-in-out 0.4s;
  -moz-animation: fadeOutModal ease-in-out 0.4s;
  -o-animation: fadeOutModal ease-in-out 0.4s;
  -ms-animation: fadeOutModal ease-in-out 0.4s;
}

@keyframes fadeOutModal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
