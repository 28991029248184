.reports-container table {
  padding: 0;
  width: 50%;
  margin: 30px auto;
  font-size: 12px;
}

.reports-container table thead {
  background-color: rgba(0, 0, 0, 0.2);
  color: #0f0f0f;
  margin: 0;
}

.reports-container table thead th {
  padding: 8px;
  text-align: center;
}

.reports-container table tbody tr {
  background-color: #f1f1f1;
}

.reports-container table tbody tr td {
  vertical-align: top;
  padding: 6px;
  text-align: center;
}

@media print {
  .reports-container {
    padding: 0;
    background-color: #fff;
  }
  
  .reports-container .reports-section h2 {
    text-align: center;
  }

  .reports-container .reports-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0;
  }
  
  .reports-container .reports-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0;
  }
  
  .reports-container .reports-header h2,
  .reports-container .reports-header h4 {
    margin: 0;
  }

  .reports-container .reports-header h2, .reports-container .reports-header h4 {
    margin: 0;
  }
  
  .reports-container .reports-section h3 {
    text-align: center;
    text-decoration: underline;
  }

  .reports-container .reports-section .chart-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .page-break {
    page-break-after: always;
  }
}

@media screen {
  .reports-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
  }

  .reports-container .reports-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0;
  }

  .reports-container .reports-header h2, .reports-container .reports-header h4 {
    margin: 0;
  }
  
  .reports-container .reports-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
  }

  .reports-container .reports-section h2, .reports-container .reports-section h3 {
    text-align: center;
  }

  .reports-container .reports-section h3 {
    text-decoration: underline;
  }

  .reports-container .reports-section:last-child {
    margin-bottom: 60px;
  }
  
  .reports-container .reports-section .chart-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.access-code-input {
  transition: height 0.6s ease-in-out;
  overflow: hidden;
  height: 0;
}

.access-code-input.expanded {
  transition: height 0.6s ease-in-out;
  height: 47px;
}

.html-bar-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.html-bar-chart-container .horizontal-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 680px;
  margin-right: 25px;
}

.html-bar-chart-container .horizontal-bar .horizontal-bar-label {
  font-size: 12px;
  margin: 0;
  min-width: 180px;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.html-bar-chart-container .horizontal-bar .bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.html-bar-chart-container .horizontal-bar .bar {
  height: 10px;
}

.html-bar-chart-container .horizontal-bar .bar-value {
  font-size: 12px;
  margin: 0 0 0 5px;
  min-width: 40px;
}

.section-description {
  margin: 0;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}