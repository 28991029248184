.grid-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  position: relative;
}

.grid-item {
  position: relative;
  height: 0;
  padding: 56.25% 0 0 0;
  background-color: #1e2a36;
  outline: 2px solid #181e25;
  display: flex;
  flex: 1;
}

.grid-item.editing {
  outline: 2px solid #14759e;
}
