.switch-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.switch-container > .label {
  flex: 1;
  text-align: left;
  font-size: 14px;
  margin-left: 5px;
  color: rgb(147, 147, 148);
  user-select: none;
}
