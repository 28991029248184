html,
body,
#root,
.app,
.body {
  width: 100%;
  height: 100%;
}

.route-container {
  width: 100%;
  height: calc(100% - 64px);
  position: relative;
}

.top-bar {
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
}

.app {
  display: flex;
  text-align: center;
  background-color: #181f26;
}

div {
  outline: none;
}

div.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-link {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  color: #a9aaab;
  cursor: pointer;
}

.body {
  -webkit-transition: width 1s ease-in-out, min-width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out, min-width 1s ease-in-out;
  -o-transition: width 1s ease-in-out, min-width 1s ease-in-out;
  transition: width 1s ease-in-out, min-width 1s ease-in-out;
}

.route-container {
  -webkit-animation: fade-in 1s;
  -moz-animation: fade-in 1s;
  -o-animation: fade-in 1s;
  animation: fade-in 1s;
  overflow: hidden;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  font-family: Raleway;
  font-size: 20px;
  color: #a9aaab;
  padding: 20px;
  text-align: center;
}

.top-bar .header {
  font-family: Raleway;
  font-size: 18px;
  color: #a9aaab;
  margin: 20px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: calc(50% - 45px);
  min-width: 90px;
  padding: 0;
}

.top-bar .breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  margin: 12px 0;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  color: #a9aaab;
  text-align: center;
  max-width: 40%;
  width: 40%;
}

.top-bar .breadcrumb span {
  margin-right: 15px;
}

.top-bar .breadcrumb-item {
  display: flex;
  align-items: center;
  max-width: 45%;
}

.top-bar .breadcrumb-item img {
  width: 22px;
  margin-right: 10px;
}

.top-bar .breadcrumb-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.admin-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 12px 0;
}

.admin-settings .logout {
  color: #a9aaab;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  font-size: 16px;
}

.admin-settings .socket-connection-status {
  color: #a9aaab;
  margin-right: 10px;
}

.admin-settings .socket-connection-status span {
  margin: 0 10px;
}

.admin-settings .settings-menu-item {
  color: #a9aaab;
  cursor: pointer;
  padding: 0 10px;
  text-align: left;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.admin-settings .settings-menu-item img {
  margin-right: 5px;
}

.swal-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.swal-modal {
  background-color: #1e2a35;
  border: 2px solid #4e6379;
}

.swal-button--confirm {
  background-color: #2b763a;
}

.swal-button--confirm:not([disabled]):hover {
  background-color: #2b763a;
}

.swal-title {
  color: #a9aaab;
}

.swal-text {
  color: #a9aaab;
}

.swal-custom-button--red {
  background-color: #711a20 !important;
  color: #fff;
}

.swal-custom-button--grey {
  background-color: #4e6379 !important;
  color: #fff;
}

input[type='range'] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 70%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #939394;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #4e6379;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #4e6379;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #939394;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #939394;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #4e6379;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #4e6379;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #939394;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-fill-upper {
  background: #939394;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #4e6379;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #4e6379;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: #939394;
}
input[type='range']:focus::-ms-fill-upper {
  background: #939394;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.icon .tooltip.top {
  visibility: hidden;
  width: 80px;
  background-color: #1D242F;
  color: #dedede;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 10;
  bottom: 125%;
  left: 50%;
  margin-left: -40px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

/* Tooltip arrow */
.icon .tooltip.top::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1D242F transparent transparent transparent;
}

.icon:hover .tooltip.top {
  visibility: visible;
  opacity: 1;
}

.swal-wide-content {
  width: 100%;
  max-width: 600px;
}
