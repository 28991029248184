.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.login-container .login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1f2a38;
  padding: 40px;
  width: 40%;
  max-width: 500px;
  position: relative;
}

.login-container .login-form-container form {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.login-container .login-form-container .title {
  font-family: Raleway;
  font-size: 20px;
  color: #a9aaab;
  margin-bottom: 60px;
  font-weight: 900;
}

.login-container .login-form-container .subtitle {
  font-family: Raleway;
  font-size: 16px;
  color: #a9aaab;
  margin: 15px;
  font-weight: 300;
}

.login-container .login-form-container .alert {
  color: white;
  background-color: #c6103a;
  border-radius: 2px;
  padding: 8px 20px;
  position: absolute;
  top: 100px;
  font-size: 13px;
  opacity: 0;
}

.fade-in {
  animation: fadeInFadeOut ease-in-out 5s;
  -webkit-animation: fadeInFadeOut ease-in-out 5s;
  -moz-animation: fadeInFadeOut ease-in-out 5s;
  -o-animation: fadeInFadeOut ease-in-out 5s;
  -ms-animation: fadeInFadeOut ease-in-out 5s;
}

@keyframes fadeInFadeOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
