.users-container {
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  height: calc(100% - 20px);
}

.users-container .users-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin: 20px 0;
  background-color: #131b21;
}

.users-container .users-content .user-list {
  width: 450px;
  min-width: 450px;
  height: 100%;
  overflow: auto;
  position: relative;
  border-right: 2px solid #1e272f;
}

.users-container .users-content .user-list .add-user {
  color: #2b763a;
  cursor: pointer;
  align-items: center;
  border-top: 2px solid #1e272f;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.users-container .users-content .user-list .add-user img {
  margin-right: 10px;
}

.users-container .users-content .user-detail {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.users-container .users-content .user-detail::-webkit-scrollbar {
  width: 6px;
}

.users-container .users-content .user-detail::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.users-container .users-content .user-detail::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.users-container .users-content .user-detail .form {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.users-container .users-content .user-detail .form .user-actions {
  margin-top: 40px;
}

.users-container .users-content .user-detail .form .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.users-container .users-content .user-detail .form .header .link {
  color: #2b763a;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  margin-left: 60px;
}

.users-container .users-content .user-detail .form .header .title {
  padding-left: 15px;
}

.users-container .user-list .user-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #a9aaab;
  padding: 15px 20px;
  border-bottom: 2px solid #1c242d;
  cursor: pointer;
}

.users-container .user-list .user-list-item:hover {
  background: #24313f;
}

.users-container .user-list .user-list-item.selected {
  background: #24313f;
}

.user-list-item .username,
.user-list-item .user-role {
  text-align: left;
}

.users-container .user-list .user-list-item .user-option {
  margin: 8px;
}
