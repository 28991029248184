.image-editor-container {
  display: grid;
  grid-template-columns: 35% 35% 30%;
  grid-template-rows: auto 40px;
  width: 100%;
}

.image-editor {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-self: center;
  background: #181f26;
  position: relative;
}

.image-crop {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-self: center;
}

.image-position {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 1;
  justify-self: center;
}

.image-options {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.image-options .options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.image-cropper {
  position: relative;
  margin: 10px;
}

.cropped-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
}

.cropped {
  position: absolute;
  outline: 2px solid green;
  z-index: 3;
}

.image-overlay {
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  top: 0;
}

.handler {
  position: absolute;
  width: 8px;
  height: 8px;
  background: green;
  z-index: 999;
}

.handler.handler-top-left,
.handler.handler-bottom-right {
  cursor: nwse-resize;
}

.handler.handler-top,
.handler.handler-bottom {
  cursor: ns-resize;
}

.handler.handler-top-right,
.handler.handler-bottom-left {
  cursor: nesw-resize;
}

.handler.handler-right,
.handler.handler-left {
  cursor: ew-resize;
}

.size-indicator {
  position: absolute;
  pointer-events: none;
  user-select: none;
}

.size-indicator .label {
  position: absolute;
  width: 40px;
  padding: 5px 0;
  font-size: 12px;
  background-color: #141b21;
  top: 11px;
  left: calc(50% - 20px);
}

.size-indicator.horizontal {
  bottom: -80px;
}

.size-indicator.vertical {
  top: calc(50% - 25px);
}

.resize-tool {
  position: absolute;
  border: 2px solid rgb(43, 118, 58);
  background-color: rgba(43, 118, 58, 0.4);
  box-sizing: border-box;
  z-index: 999;
}

.resize-tool .handler {
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 1000;
}

.resize-tool .handler.handler-top-left {
  top: 0;
  left: 0;
}

.resize-tool .handler.handler-bottom-right {
  bottom: 0;
  right: 0;
}

.resize-tool .handler.handler-top-right {
  top: 0;
  right: 0;
}

.resize-tool .handler.handler-bottom-left {
  bottom: 0;
  left: 0;
}

.resize-tool .handler.handler-top-left,
.resize-tool .handler.handler-bottom-right {
  cursor: nwse-resize;
}

.resize-tool .handler.handler-top-right,
.resize-tool .handler.handler-bottom-left {
  cursor: nesw-resize;
}
