.file-uploader {
  margin: 10px 20px;
}

.file-uploader-dropzone {
  padding: 10px;
  border: 1px dashed #65696f;
  color: #65696f;
  user-select: none;
  font-size: 14px;
}

.file-uploader-dropzone.active {
  border: 1px dashed #6ab24f;
  color: #6ab24f;
}

.file-progress {
  color: #cdcecf;
  flex: 1;
  margin: 0 60px;
  background: #171f27;
  border-radius: 5px;
  position: relative;
}

.file-progress .upload-info {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-progress .upload-percentage {
  font-size: 13px;
  width: 40px;
  color: #6ab24f;
  text-align: left;
}

.file-progress .upload-info .upload-message {
  color: #cdcecf;
  font-size: 16px;
  flex: 1;
  text-align: center;
}

.file-progress .upload-info .upload-details {
  width: 40px;
  cursor: pointer;
}

.file-progress .progress-detail {
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  background: #24303e;
  padding: 20px;
  padding-top: 5px;
  z-index: 9999;
}

.file-progress .file-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.progress-bar {
  width: 100%;
  border-bottom: 1px solid #131a20;
  overflow: hidden;
}

.progress-bar .progress {
  width: 0;
  border: 2px solid #6ab24f;
  margin-bottom: 2px;
}
