.html-editor-container {
  margin: 20px 60px;
  height: calc(100% - 50px);
  width: auto;
  display: flex;
  flex-direction: column;
}

.html-editor-container .content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.html-editor-container .panel__top {
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
}

.html-editor-container .panel__basic-actions {
  position: initial;
}

.html-editor-container .panel__switcher {
  position: initial;
}

.html-editor-container .panel__devices {
  position: initial;
}

.html-editor-container .panel__right {
  position: initial;
  width: 300px;
  overflow-y: auto;
}

.html-editor-container .editor {
  flex: 1;
  border: 3px solid #1e2a36;
  box-sizing: border-box;
}

.gjs-mdl-content {
  text-align: left;
}

/* Theming */

/* Primary color for the background */
.gjs-one-bg {
  background-color: #1e2a36;
}

/* Secondary color for the text color */
.gjs-two-color {
  color: #787878;
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: #1c242d;
  color: #787878;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: #2b763a;
}

.gjs-blocks-cs {
  height: 100%;
}
