/*
  RadioButtons Component Style
*/
.form-control input[type='radio'],
.inline-radio-buttons input[type='radio'] {
  position: absolute;
  left: -99999px;
}

.inline-radio-buttons.small {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control input[type='radio'] + .radio-label,
.inline-radio-buttons input[type='radio'] + .radio-label {
  position: relative;
  cursor: pointer;
  padding: 3px 0 0 30px;
  margin-right: 15px;
  color: #a9aaab;
}

.inline-radio-buttons.small input[type='radio'] + .radio-label {
  position: relative;
  cursor: pointer;
  padding: 3px 0 0 18px;
  margin-right: 12px;
  color: #a9aaab;
}

.form-control input[type='radio'] + .radio-label::before,
.inline-radio-buttons input[type='radio'] + .radio-label::before {
  content: '';
  background: transparent;
  width: 20px;
  height: 20px;
  border: 1px solid #287737;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 0px;
}

.form-control input[type='radio']:checked + .radio-label::after,
.inline-radio-buttons input[type='radio']:checked + .radio-label::after {
  content: '';
  background: #287737;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 4px;
}

.form-control input[type='radio'] + .radio-label.small::before,
.inline-radio-buttons input[type='radio'] + .radio-label.small::before {
  content: '';
  background: transparent;
  width: 10px;
  height: 10px;
  border: 1px solid #287737;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 0px;
}

.form-control input[type='radio']:checked + .radio-label.small::after,
.inline-radio-buttons input[type='radio']:checked + .radio-label.small::after {
  content: '';
  background: #287737;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 3px;
}
