.help-desk {
  padding: 0 60px;
  height: calc(100% - 20px);
}

@media screen and (max-width: 1366px) {
  .help-desk {
    padding: 0 10px;
  }
}

.help-desk .content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 50px);
  background-color: #131b21;
  margin-top: 10px;
}

.help-desk .content .list-header {
  font-family: Raleway;
  font-size: 16px;
  color: #a9aaab;
  padding: 16px;
  text-align: left;
  border-bottom: 2px solid #1e272f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.help-desk .participant-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #a9aaab;
  padding: 4px 8px;
  font-size: 14px;
  border-bottom: 2px solid #1e272f;
}
.help-desk .participant-list-header:hover {
  cursor: pointer;
}
.help-desk .participant-list-header:hover span {
  text-decoration: underline;
  cursor: pointer;
}

.help-desk .participant-list-header span {
  margin-left: 8px;
  cursor: default;
}

.help-desk .content .list-header .tab {
  padding: 16px;
  display: flex;
  flex: 1;
  cursor: pointer;
  font-size: 13px;
  height: 30px;
  align-items: center;
  text-align: center;
}

.help-desk .content .list-header .tab.active {
  background-color: #36424d;
}

.help-desk .content .list-header .tab:first-child {
  border-right: 2px solid #1e272f;
}

.help-desk .content .list-header .tab:last-child {
  border-left: 2px solid #1e272f;
}

.help-desk .content .participants-queue {
  position: relative;
  border-right: 2px solid #1e272f;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.help-desk .content .main {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.help-desk .content .main .media-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.help-desk .content .main .media-container .sidebar {
  background-color: #131b21;
  height: 100%;
  width: 250px;
}

/* @media screen and (min-width: 1920px) {
  .help-desk .content .main .media-container .sidebar.sidebar-left {
    width: 300px;
  }
} */

.property-list {
  margin: 10px;
}

.property-list .property {
  display: flex;
  color: white;
  font-size: 11px;
  margin: 4px;
  justify-content: space-between;
}

.property-list .property .value {
  text-align: right;
}

.property-list .property.clickable .value {
  text-decoration: underline;
  cursor: pointer;
}

.property-list .property .label {
  text-align: left;
}

.help-desk .content .main .media-container .media-devices {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.help-desk .content .main .media-container .media-devices .device-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 5px 5px 5px;
}

.help-desk .content .main .media-container .media-devices .device-list-container .label {
  text-align: left;
  font-size: 11px;
  color: #a9aaab;
}

.help-desk .content .main .media-container {
  position: relative;
  display: flex;
  min-height: 404px;
  height: 50%;
}

.help-desk .content .main .media-container .local-media {
  position: absolute;
  width: 250px;
  height: 141px;
  background: black;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.help-desk .content .main .media-container .local-media video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.help-desk .content .main .media-container .media {
  width: 320px;
  height: 180px;
  background-color: black;
  position: relative;
  top: calc(50% - (240px / 2));
}

.help-desk .content .main .media-container .media video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.help-desk .content .main .media-container .media .platform {
  color: #66686a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin: 0 10px;
}

.help-desk .content .main .options-container {
  flex: 1 1;
  border-top: 2px solid #1e272f;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.help-desk .content .main .options-container .form-container {
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
}

.help-desk .content .main .options-container .form-container h4 {
  color: #a9aaab;
  text-align: left;
}

.help-desk .content .connected-participants {
  position: relative;
  border-left: 2px solid #1e272f;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.help-desk .participant-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f0f0f0;
  margin-bottom: 2px;
  cursor: pointer;
  font-size: 13px;
  background-color: #20272f;
  padding: 8px;
  margin-bottom: 2px;
  min-height: 80px;
  position: relative;
}

.help-desk .participant-list-item.selected {
  background-color: #36424d;
}

.help-desk .participant-list-item .indicator {
  width: 10px;
  background-color: #888888;
}

.help-desk .participant-list-item .indicator.on-air {
  background-color: #43b77a;
}

.help-desk .participant-list-item .indicator.off-air {
  background-color: #b71330;
}

.help-desk .participant-list-item .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #1e272f;
  border-right: 1px solid #1e272f;
}

.help-desk .participant-list-item .info .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #1e272f;
  padding: 5px;
}

.help-desk .participant-list-item .session-time {
  font-size: 11px;
}

.help-desk .participant-list-item .status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 10px 0 5px;
}

.help-desk .participant-list-item .status.online {
  background: #43b77a;
}

.help-desk .participant-list-item .status.offline {
  background: #b71330;
}

.help-desk .participant-list-item .status.queue {
  background: #b7771e;
}

.help-desk .participant-list-item .status.connecting {
  background: #008ada;
}

.help-desk .participant-list-item .name {
  font-weight: 600;
  text-align: left;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.help-desk .participant-list-item .row {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.help-desk .participant-list-item .info .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #1f272f;
}

.help-desk .participant-list-item .info .content .notes {
  background: #1b2733;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
}

.media-devices {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.media-devices .device-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.media-devices .device-list-container .label {
  text-align: left;
  /* font-size: 13px; */
  color: #a9aaab;
}

.local-media .controls {
  width: 100%;
  height: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
}

.settings {
  display: flex;
  flex-direction: column;
  background-color: #181f26;
  width: 320px;
  border: 2px solid #1e272f;
  box-shadow: 0px 0px 7px 1px #0a081259;
  padding: 10px 10px 15px 10px;
  margin-top: 0;
}

.settings .device-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.settings .device-list-container .label {
  text-align: left;
  /* font-size: 13px; */
  color: #a9aaab;
}
