.paginated {
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.pagination {
  list-style: none;
  padding: 0;
  position: relative;
  transform: translateX(-50%);
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid rgba(255,255,255,0.5);
  display: flex;
  justify-content: center;
  font-size: 25px;
  padding: 10px;
}

ul.pagination li a:hover {
  cursor: pointer;
}

ul.pagination li a {
  text-decoration: none;
  color: white;
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding: 5px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: rgb(79, 100, 120);
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: white;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
