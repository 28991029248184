.events-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 60px;
  height: calc(100% - 20px);
}

.events-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: #131b21;
}

.left-column {
  flex: 4;
  height: 100%;
  overflow: auto;
}

.events-list {
  position: relative;
  height: 100%;
}

.events-list .event-list-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #c2c2c2;
  font-size: 18px;
  border-bottom: 2px solid #1c242d;
}

.events-list .event-list-tabs .tab {
  flex: 1;
  padding: 15px 10px;
  cursor: pointer;
  user-select: none;
}

.events-list .event-list-tabs .tab.active {
  background-color: #36424d;
}

.events-list .event-list-item {
  border-bottom: 2px solid #1c242d;
  cursor: pointer;
  overflow: hidden;
}

.events-list .event-list-item:hover {
  background: #24313f;
}

.events-list .add-event {
  color: #2b763a;
  cursor: pointer;
  align-items: center;
  border-top: 2px solid #1e272f;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.events-list .add-event img {
  margin-right: 10px;
}

.right-column {
  flex: 6;
  height: 100%;
  border-left: 2px solid #1e272f;
}

.event {
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #c2c2c2;
  position: relative;
}

.event-option {
  margin: 8px;
}

.event-option img {
  width: 24px;
  height: 24px;
}

.event-info {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event-title {
  color: #c2c2c2;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.event-title span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-date {
  color: #65696f;
  font-size: 16px;
  text-align: left;
}

.event.selected {
  background-color: #23313f;
}

.event-assets {
  height: 100%;
  position: relative;
}

.event-assets-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  padding: 0 15px;
  border-bottom: 2px solid #1e272f;
}

.event-assets-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 135px;
  padding: 0 15px;
  border-top: 2px solid #1e272f;
  color: #fff;
}

.event-assets-title {
  color: #65696f;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.event-settings-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1;
  background-color: #1e2a36;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.event-settings-container .form-container {
  background-color: #1e2a36;
  padding: 20px;
}

.event-settings-container .form-container .modal-form {
  height: auto !important;
}

.custom-form-header,
.event-settings-container .custom-form-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #adadad;
  min-width: 560px;
  width: 720px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.event-settings-container .custom-form-header {
  margin-left: 0;
}

.grant-access-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c2c2c2;
}

.grant-access-modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}