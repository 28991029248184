.polls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 60px;
  height: calc(100% - 20px);
  position: relative;
}

.polls-list {
  width: 300px;
  height: 100%;
  background-color: #131b21;
}

.polls-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid #1e272f;
}

.polls-list-item:hover {
  background: #24313f;
}

.polls-list-item.selected {
  background-color: #23313f !important;
}

.polls-list-item .poll-name {
  flex: 1;
  color: #a9aaab;
  text-align: left;
  cursor: pointer;
}

.polls-list-item button {
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.polls-list-item button:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.poll-editor {
  flex: 1 1;
  padding: 20px;
  height: calc(100% - 40px);
  background-color: #131b21;
  border-left: 2px solid #1e272f;
  overflow-y: auto;
  position: relative;
}

.poll-editor .section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.poll-editor .section .title {
  color: #a9aaab;
  font-size: 18px;
  text-transform: uppercase;
}

.add-poll {
  color: #2b763a;
  cursor: pointer;
  align-items: center;
  border-top: 2px solid #1e272f;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  width: 150px;
  /* position: absolute; */
  bottom: 0;
}

.poll-results {
  background: white;
  width: calc(100% - 40px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.poll-results h1 {
  text-align: center;
}

.poll-results .chart-container {
  width: 90%;
  flex: 1;
}

.poll-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;
  color: #a9aaab;
  margin-right: 10px;
}

.poll-status span {
  color: #fff;
}
