.navbar {
  height: 100%;
  background-color: #1e2a37;
  -webkit-transition: width 0.6s ease-in-out, min-width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out, min-width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out, min-width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out, min-width 0.6s ease-in-out;
  width: 200px;
  min-width: 200px;
  overflow-y: auto;
}

.navbar.collapsed {
  -webkit-transition: width 1s ease-in-out, min-width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out, min-width 1s ease-in-out;
  -o-transition: width 1s ease-in-out, min-width 1s ease-in-out;
  transition: width 1s ease-in-out, min-width 1s ease-in-out;
  width: 68px;
  min-width: 68px;
}

.navbar .link {
  text-decoration: none;
  border-left: 2px solid transparent;
  -webkit-transition: background-color 0.25s linear;
  -moz-transition: background-color 0.25s linear;
  -o-transition: background-color 0.25s linear;
  transition: background-color 0.25s linear, border 0.25s linear;
  display: flex;
  align-items: center;
  color: #777;
  height: 70px;
}

.navbar .link:hover {
  background-color: #181f26;
}

.navbar .link.active {
  background-color: #181f26;
}

.navbar .link.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.3;
}

.navbar .link .icon {
  margin: 0 12px;
  width: 36px !important;
  height: 36px;
}

.navbar .link .label {
  font-size: 16px;
  -webkit-transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
  transform: scale(1);
  opacity: 1;
}

.navbar .link .label.collapsed {
  -webkit-transition: transform 0.5s ease-in-out, opacity 0.4s ease-in-out;
  -moz-transition: transform 0.5s ease-in-out, opacity 0.4s ease-in-out;
  -o-transition: transform 0.5s ease-in-out, opacity 0.4s ease-in-out;
  transition: transform 0.5s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 0;
  transform: scale(0);
}

.navbar-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding-right: 15px;
}
