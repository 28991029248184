.streams {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 60px;
}

.streams .on-air-label {
  display: flex;
  background: #181f26;
  color: #e81d32;
  padding: 20px;
  font-size: 24px;
  font-weight: 600;
  text-shadow: 0px 0px 6px rgb(255 0 0 / 50%);
}

.streams .on-air-streams {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'onAirSlot1 onAirSlot2'
    'onAirSlot3 onAirSlot4';
  width: 100%;
  height: 100%;
}

.streams .on-air-streams .stream {
  display: flex;
  flex-direction: column;
  color: #bbbcbc;
  align-self: center;
  justify-self: center;
}

.streams .on-air-streams .stream .num {
  font-weight: 400;
  width: 36px;
  height: 36px;
  position: absolute;
  background-color: rgba(32, 32, 32, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.streams .on-air-streams .stream .video-container {
  background: #1e2a36;
  position: relative;
  width: 85%;
  align-self: center;
  aspect-ratio: 16 /9;
}

.streams .on-air-streams .stream .video-container video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.program-feed {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 40px;
}

.program-feed video {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  max-height: 720px;
  background-color: #181f26;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tabs .tab-button {
  position: relative;
  display: inline-block;
  padding: 7px 28px;
  border-radius: 2px;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: Raleway;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  user-select: none;
  margin: 0 5px;
  background-color: transparent;
  color: #4e6379;
}

.tabs .tab-button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.tabs .tab-button:hover {
  color: #287737;
}

.tabs .tab-button.active {
  color: #287737;
}
