.go-live,
.gateway,
.cluster {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 30px;
  overflow: auto;
}

.go-live .content,
.gateway .content,
.cluster .content {
  flex: 1;
  margin: 0 auto;
}

.go-live .instances,
.gateway .instances,
.cluster .instances {
  display: flex;
  flex: 1;
  align-items: flex-start;
}

.go-live .footer {
  margin-top: 25px;
  padding: 20px 0;
  text-align: center;
}

.instance-container {
  background-color: #1e2a38;
  color: #f3f3f3;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  height: auto;
  min-height: 720px;
  width: 330px;
  min-width: 330px;
}

.instance-container .header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: normal;
  font-size: 18px;
  color: #f3f3f3;
  padding: 20px 15px 0px;
}

.instance-container .header .name {
  font-weight: bold;
}

.instance-container .status {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 10px;
  letter-spacing: 1px;
}

.instance-container .status .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.instance-container .status span {
  font-weight: bold;
  text-transform: uppercase;
}

.instance-container .server-type {
  padding: 0px 15px;
}

.instance-container .server-type > img {
  margin: 15px;
  margin-top: 5px;
  width: 70%;
}

.instance-container .instance-id {
  font-size: 14px;
  color: #43586f;
  margin-bottom: 5px;
}

.instance-container .instance-type {
  font-size: 14px;
  color: #43586f;
  margin-bottom: 2px;
  display: inline-block;
  height: 22px;
  border-bottom: 1px solid transparent;
}

.instance-container .ip-address {
  font-size: 14px;
  color: #43586f;
  margin-bottom: 2px;
  display: inline-block;
  height: 22px;
}

.instance-container .instance-type.stopped:hover {
  border-bottom: 1px solid #43586f;
  cursor: pointer;
}

.instance-container .instance-type .edit-capacity button {
  background: transparent;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
  user-select: none;
  opacity: 0.7;
}

.instance-container .instance-type .edit-capacity button img {
  width: 15px;
  height: 15px;
  margin: 0;
}

.instance-container .uptime {
  font-size: 15px;
  color: #43586f;
  height: 20px;
  margin-bottom: 5px;
}

.instance-container .monitoring {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 5px 0 5px;
}

.instance-container .actions {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.widget {
  border-bottom: 1px solid #182026;
  padding: 5px 15px 15px 15px;
}

.widget .title {
  color: #8b8b8b;
  font-weight: 600;
  margin-bottom: 2px;
}

.widget .control .progress-container {
}

.widget .control .progress-container .labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.widget .control .progress-container .labels .percentage {
  color: #69b846;
}

.widget .control .progress-container .progress-bar {
  background: rgb(105, 184, 70);
  background: linear-gradient(90deg, rgba(105, 184, 70, 1) 0%, rgba(185, 183, 17, 1) 50%, rgba(214, 0, 0, 1) 100%);
  width: 100%;
  overflow: hidden;
  border: 0;
}

.widget .control .progress-container .progress-bar .progress {
  position: relative;
  width: 100%;
  left: 0%;
  height: 5px;
  background-color: #293746;
  transition: left 0.5s ease;
  -webkit-transition: left 0.5s ease;
  -moz-transition: left 0.5s ease;
  -o-transition: left 0.5s ease;
  transition: left 0.5s ease;
  border: 0;
  margin: 0;
}

.vertical-progress-bar {
  background: rgb(105, 184, 70);
  background: linear-gradient(0deg, rgba(105, 184, 70, 1) 0%, rgba(185, 183, 17, 1) 50%, rgba(214, 0, 0, 1) 100%);
  height: 40px;
  width: 5px;
  overflow: hidden;
  border: 0;
}

.vertical-progress-bar .progress {
  position: relative;
  height: 40px;
  width: 5px;
  bottom: 50%;
  background-color: #293746;
  transition: left 0.5s ease;
  -webkit-transition: left 0.5s ease;
  -moz-transition: left 0.5s ease;
  -o-transition: left 0.5s ease;
  transition: left 0.5s ease;
  border: 0;
  margin: 0;
}

.widget .control .network-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget .control .network-container .icon-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.golive-button {
  font-size: 44px;
  padding: 15px 30px;
  background-color: #711a20;
  color: #fff;
  position: relative;
  display: inline-block;
  border-radius: 2px;
  border: 0;
  outline: none;
  cursor: pointer;
  user-select: none;
  margin: 30px;
}

.studio-gateway {
  background-color: #1e2a38;
  color: #f3f3f3;
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  height: auto;
  min-width: 330px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.cluster .content .server {
  display: flex;
  flex-direction: row;
}

.cluster .content .server .status-text {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 10px;
  letter-spacing: 1px;
}

.cluster .content .server .status-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}