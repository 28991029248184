.list-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.list-root .list-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-root .list-wrapper::-webkit-scrollbar {
  width: 6px;
}
.list-root .list-wrapper::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.list-root .list-wrapper::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.list-root .list-wrapper .list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sortable-list-item {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #2b7538;
  color: #fff;
  border: 0;
}

.sortable-list-item .item-label {
  padding-left: 25px;
}
