.breakout-rooms {
  display: flex;
  flex-direction: row;
  height: calc(100% - 20px);
  margin: 0 60px;
}

.breakout-rooms .configuration {
  display: flex;
  height: 60px;
}

.breakout-rooms .configuration .num-rooms-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0;
  width: 205px;
}

.breakout-rooms .configuration .num-rooms-select .label {
  margin-right: 15px;
  color: #a9aaab;
}

.breakout-rooms .configuration .actions {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.breakout-rooms .configuration .actions .timer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breakout-rooms .configuration .actions .timer-wrapper .timer {
  margin-right: 20px;
  color: #a9aaab;
}

.breakout-rooms .content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.breakout-rooms .participant-list {
  width: 320px;
  position: relative;
  background-color: #131b21;
  margin-right: 2px;
}

.breakout-rooms .participant-list .list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid #181e25;
}

.breakout-rooms .participant-list .list-header .column {
  color: #d9e8ff;
  margin: 15px;
  cursor: pointer;
  user-select: none;
}

.breakout-rooms .participant-list .list-header .column span {
  margin-right: 10px;
}

.breakout-rooms .participant-list .participants {
  height: calc(100% - 60px);
}

.breakout-rooms .participant-list .participants .participant-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #1c242d;
  cursor: pointer;
}

.breakout-rooms .participant-list .participants .participant-list-item .name {
  text-align: left;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakout-rooms .participant-list .participants .participant-list-item:hover span {
  color: #2b763a;
}

.breakout-rooms .participant-list .participants .participant-list-item span {
  color: #c2c3c4;
  margin: 15px;
}

.breakout-rooms .content .rooms-grid-wrapper {
  flex: 1;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'room1 room2 room3 room4 room5'
    'room6 room7 room8 room9 room10'
    'room11 room12 room13 room14 room15'
    'room16 room17 room18 room19 room20';
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  height: 100%;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room-placeholder {
  display: flex;
  flex: 1;
  background-color: #19232e;
  outline: 2px solid #181e25;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #1e2a36;
  outline: 2px solid #181e25;
  position: relative;
}

.pulse {
  box-shadow: 0 0 0 0 rgba(183, 19, 48, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
  z-index: 999;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(183, 19, 48, 0.9);
  }

  60% {
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-header span {
  color: #545659;
  margin: 10px;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-header span.green {
  color: #2c763a;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-header span.yellow {
  color: #a9b325;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-header span.red {
  color: #b71330;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-participants {
  height: calc(100% - 42px);
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-participants .room-participant-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-participants .room-participant-list-item.drag {
  cursor: grab;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-participants .room-participant-list-item.support {
  border: solid 1px #b71330;
}

.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-participants .room-participant-list-item:hover {
  background: #24313f;
}
.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-participants .room-participant-list-item .participant-name {
  color: #c2c3c4;
  font-size: 12px;
  margin: 5px 10px;
  text-align: left;
}
.breakout-rooms .content .rooms-grid-wrapper .rooms-grid .room .room-participants .room-participant-list-item .status-indicator {
  min-width: 8px;
  min-height: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 10px 0 10px 10px;
}

.breakout-room-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.breakout-room-container .sidebar {
  width: 320px;
  border-right: 2px solid #1e272f;
}

.sidebar .participant-list {
  height: calc(100% - 310px);
  border-top: 2px solid #1c242d;
}
.sidebar .participant-list .room-participant {
  display: flex;
  color: #a9aaab;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #1c242d;
  padding: 15px;
}

.breakout-room-container .room-header {
  display: flex;
  color: #a9aaab;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
}

.breakout-room-container .room-header span {
  margin: 0 10px;
}

.breakout-room-container .local-media-container {
  background: #181f26;
}

.local-media-container .media-devices {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.local-media-container .media-devices .device-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}

.local-media-container .media-devices .device-list-container .label {
  text-align: left;
  font-size: 13px;
  color: #a9aaab;
}

.breakout-room-container .webcam-preview {
  width: 320px;
  height: 240px;
  position: relative;
  background-color: black;
}

.breakout-room-container .webcam-preview .name {
  color: white;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  right: 55px;
  padding: 10px;
  text-shadow: 1px 1px #000000;
  z-index: 2;
  cursor: default;
}

.breakout-room-container .webcam-preview .settings {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.breakout-room-container .webcam-preview video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.breakout-room-container .webcam-preview .media-controls {
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 36px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.breakout-room-container .webcam-preview .media-controls .media {
  display: flex;
}

.breakout-room-container .remote-media-container {
  flex: 1;
  display: flex;
}

.breakout-room-container .remote-media-container .participants-grid {
  width: 100%;
  height: 100%;
}

.breakout-room-container .remote-media-container .participants-grid .grid-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
}

.breakout-room-container .remote-media-container .presentation-area {
  flex: 1;
  border-left: 2px solid #1e272f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breakout-room-container .remote-media-container .screen-track video {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.icon-button {
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 6px;
  cursor: pointer;
  margin: 2px 10px;
  justify-content: center;
  align-items: center;
}

.icon-button .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1e272f;
  color: #a9aaab;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
}

/* Tooltip arrow */
.icon-button .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e272f transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.icon-button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.media-status {
  width: 85px;
}

.media-status .icon {
  margin: 4px;
}
