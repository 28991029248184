.button {
  position: relative;
  display: inline-block;
  padding: 7px 28px;
  border-radius: 2px;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: Raleway;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  user-select: none;
  margin: 0 5px;
}

.button .tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #1e272f;
  color: #a9aaab;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
  user-select: none;
}

/* Tooltip arrow */
.button .tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1e272f transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.button:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button .button-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button .button-content .icon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.button .button-content .text {
  line-height: 26px;
  flex: 1;
}

.button.primary {
  background-color: #287737;
  color: #fff;
}

.button.secondary {
  background-color: #4e6379;
  color: #fff;
}

.button.danger {
  background-color: #711a20;
  color: #fff;
}

.button.danger-alt {
  background-color: #1b232d;
  color: #d31e32;
}

.button.waiting {
  background-color: #a87a0f;
  color: #fff;
}

.button.light-blue {
  background-color: #008ada;
  color: #fff;
}

.button.link {
  background-color: transparent;
  color: #287737;
}

.button:active {
  position: relative;
  top: 2px;
  left: 2px;
}
