.button-group {
  margin: 0 30px 0 0;
}

.button-group .label {
  text-align: left;
  color: #a9aaab;
  margin-bottom: 5px;
}

.button-group .options {
  border: 2px solid #24313f;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.button-group .options .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  width: 85px;
  height: 65px;
  margin: 0;
  cursor: pointer;
}

.button-group .options .item.selected {
  background: #24313f;
}

.button-group .options .item img {
  width: 26px;
  height: 26px;
}

.button-group .options .item .label {
  text-align: center;
  color: #a9aaab;
  font-size: 11px;
  margin-top: 5px;
}

.button-group .options .item.selected .label {
  color: #84c05e;
}
