.assets-list {
  overflow-y: auto;
  height: calc(100% - 292px);
}

.assets-list::-webkit-scrollbar {
  width: 6px;
}
.assets-list::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
}
.assets-list::-webkit-scrollbar-thumb {
  background-color: #4e6379;
  outline: 1px solid #4e6379;
  border-radius: 3px;
}

.asset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 20px 20px 20px;
  user-select: none;
}

.asset-name {
  word-wrap: break-word;
  color: #65696f;
  font-size: 15px;
  margin-top: 5px;
  text-align: center;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  width: 100%;
  /* white-space: nowrap; */
}

.asset-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(35, 49, 63, 0.3);
}

.asset-overlay i {
  color: #4e6379;
}

.asset-type {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  background: #e4e0e0;
  border-radius: 5px;
  position: absolute;
  right: 0;
}

.asset-options {
  flex-direction: row;
  display: flex;
}

.asset-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.asset-column {
  flex: 33%;
  max-width: 33%;
}

.add-asset {
  color: #2b763a;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-asset img {
  margin-right: 10px;
}
